// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-simple-page-js": () => import("/codebuild/output/src580921701/src/money-2020-europe/src/layouts/simplePage.js" /* webpackChunkName: "component---src-layouts-simple-page-js" */),
  "component---src-layouts-landing-page-js": () => import("/codebuild/output/src580921701/src/money-2020-europe/src/layouts/landingPage.js" /* webpackChunkName: "component---src-layouts-landing-page-js" */),
  "component---src-layouts-sponsor-page-js": () => import("/codebuild/output/src580921701/src/money-2020-europe/src/layouts/sponsorPage.js" /* webpackChunkName: "component---src-layouts-sponsor-page-js" */),
  "component---src-layouts-speaker-page-js": () => import("/codebuild/output/src580921701/src/money-2020-europe/src/layouts/speakerPage.js" /* webpackChunkName: "component---src-layouts-speaker-page-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src580921701/src/money-2020-europe/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agenda-js": () => import("/codebuild/output/src580921701/src/money-2020-europe/src/pages/agenda.js" /* webpackChunkName: "component---src-pages-agenda-js" */),
  "component---src-pages-demo-js": () => import("/codebuild/output/src580921701/src/money-2020-europe/src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-faq-page-js": () => import("/codebuild/output/src580921701/src/money-2020-europe/src/pages/faq-page.js" /* webpackChunkName: "component---src-pages-faq-page-js" */),
  "component---src-pages-startups-js": () => import("/codebuild/output/src580921701/src/money-2020-europe/src/pages/startups.js" /* webpackChunkName: "component---src-pages-startups-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src580921701/src/money-2020-europe/.cache/data.json")

